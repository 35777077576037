import { BBC1, forbestransparent, NYTimes1, TechCrunch1 } from "../../assets/images";
import BrandsSliderMobile from "../../Components/BrandlogoSlider";
import BrandlogoSlider from "../../Components/BrandlogoSlider";
import './index.css'

export default function Brandlogos() {
    return (
        <>
            <div class="brands">
                <div class="container">
                    <BrandsSliderMobile />
                </div>
            </div>
        </>
    )
}