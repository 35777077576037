import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { testimonial1, testimonial3min, testimonial5min, testimonial7min } from "../../assets/images";
import { WEBSITE_NAME } from "../../config";


export default function Testimonialslider() {

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <Slider className="testimonials-slider" {...settings}>
            <div className="testimonials-item">
                <div className="testimonials-grid d-flex justify-content-between align-items-center">
                    <img src={testimonial1} alt="testimonial1" />
                    <div className="testimonials-content">
                        <p>I was overwhelmed with the thought of writing my first book, but {WEBSITE_NAME} made the process so easy. They took my jumbled thoughts and turned them into a coherent narrative. I’m forever grateful for their expertise and support!</p>
                    </div>
                </div>
            </div>

            <div className="testimonials-item">
                <div className="testimonials-grid d-flex justify-content-between align-items-center">
                    <img src={testimonial3min} alt="testimonial3-min" />
                    <div className="testimonials-content">
                        <p>I had a story in my heart for years but never knew how to express it. The ghostwriter assigned to me was a perfect match, and they helped me find my voice. The final product was beyond my expectations—I couldn’t be more proud</p>
                    </div>
                </div>
            </div>

            <div className="testimonials-item">
                <div className="testimonials-grid d-flex justify-content-between align-items-center">
                    <img src={testimonial5min} alt="testimonial5-min" />
                    <div className="testimonials-content">
                        <p>The attention to detail at {WEBSITE_NAME} is incredible. They didn’t just write my book; they made sure it reflected my personality and message. The entire team was friendly, approachable, and passionate about their work!</p>
                    </div>
                </div>
            </div>

            <div className="testimonials-item">
                <div className="testimonials-grid d-flex justify-content-between align-items-center">
                    <img src={testimonial7min} alt="testimonial7-min" />
                    <div className="testimonials-content">
                        <p>As a busy entrepreneur, I didn’t have the time to write my book. {WEBSITE_NAME} was a lifesaver! They handled everything from the research to the final draft. I felt confident throughout the process and thrilled with the end result.</p>
                    </div>
                </div>
            </div>
        </Slider>
    );
};
