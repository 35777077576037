import { logowhite } from "../../assets/images";
import { EMAIL, EMAIL_HREF, PHONE, PHONE_HREF, WEBSITE_NAME } from "../../config";
import './index.css'

export default function Footer() {
    return (
        <>
            <footer className="footer-sec">
                <div className="container">
                    <div className="row">
                        <div class="col-lg-9 col-md-12 col-sm-12">
                            <div class="contact-footer">
                                <a href={EMAIL_HREF} class="phone" >
                                    <span>{EMAIL}</span>
                                    <div class="icon-circle"></div>
                                </a>
                                <a href={PHONE_HREF} class="email "> {PHONE} <div class="icon-circle"></div></a>
                            </div>
                            <div class="copyright-social">
                                <p>© 2024, {WEBSITE_NAME}, All right reserved.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer-logo">
                                <img src={logowhite} />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}