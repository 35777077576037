import React, { useState } from 'react';
import axios from 'axios';
import {
    WEBSITE_NAME
} from '../../config/index'
import { loader } from '../../assets/images';

const BannerForm = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        phone: '',
    });

    const onSubmitFunc = async (e) => {
        e.preventDefault();

        console.log('formData', formData);
        if (formData.phone.length < 9) {
            setErrors(true);
        } else {
            const currentUrl = window.location.href;
            const protocol = window.location.protocol; // "https:"
            const hostname = window.location.hostname; // "creativeghostwriters.org"

            // Construct the base URL
            const baseUrl = `${protocol}//${hostname}`;
            const queryStringFormData = new URLSearchParams(formData).toString();

            let finalReq = {
                ...formData,
                source: `${currentUrl}${queryStringFormData}`,
                domain: baseUrl,
                lead_url: currentUrl,
                url: `${currentUrl}${queryStringFormData}`,
            };
            try {
                setLoading(true);
                const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=239139';
                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                };

                const formDataString = new URLSearchParams(finalReq).toString();

                const response = await axios.post(apiUrl, formDataString, config);
                if (response.status == 200) {
                    // toast.success("Thank you for filling out the information");
                    // console.log(response.data.data.id);
                    const queryString = new URLSearchParams(
                        response.data.data
                    ).toString();
                    console.log('queryString', queryString);
                    setLoading(false);
                    setErrors(false);
                    setFormData({
                        name: '',
                        email: '',
                        message: '',
                        phone: '',
                    });
                    // naviagte(`/thank-you?${queryString}`)
                    window.location.href = `https://www.turnerpublishingcompany.co/thankyou?${queryString}`;
                }
                console.log('responseresponseresponse', response);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };


    return (
        <form class="main-form form_submission" method="POST" onSubmit={onSubmitFunc}>
            <div class="field name">
                <i class="fa fa-user"></i>
                <input type="text" placeholder="Enter Your Name" name="name" required onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
            </div>
            <div class="field email">
                <i class="fa fa-envelope"></i>
                <input type="email" name="email" placeholder="Email Address" class="email" required onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
            </div>
            <div class="field phone">
                <i class="fa fa-phone"></i>
                <input type="number" class="number" minLength="10" maxlength="12" placeholder="Phone Number" name="phone" required="" onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
            </div>
            <div class="submit-btn">
                {loading == true ? (
                    <div class="loader">
                        <img alt="loader" src={loader} />
                    </div>
                ) : (
                    <button type="submit" id="signupBtn" name="signupForm" class="learn-more-tab">Reserve this discount</button>
                )}
            </div>
        </form>
    );
}

export default BannerForm;