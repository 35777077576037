import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { WEBSITE_NAME } from '../../config';
import { loader } from '../../assets/images';
import { ModalBody } from 'react-bootstrap';
import './index.css'


export const ModalSection = ({ open, onCloseModal }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    brief: '',
    phone: '',
  });

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "creativeghostwriters.org"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=239139';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            brief: '',
            phone: '',
          });
          // naviagte(`/thank-you?${queryString}`)
          window.location.href = `https://www.turnerpublishingcompany.co/thankyou?${queryString}`;
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <Modal className='modal fade'
      show={open} onHide={onCloseModal}
      size='md'
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body className='entry-box'>
        <a href="javascript:;" id="entry-box_close" class="closed" onClick={onCloseModal}>X</a>
        <form class="main-form form_submission" method="POST" onSubmit={onSubmitFunc}>
          <div class="col-sm-12 entry-right">
            <div class="text-1">
              <h1><span class="red">50% off</span> ON ALL SERVICES</h1>
              <p>Fill out this brief form to get your <br /><strong>Discount reserved.</strong></p>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" required="" name="name" placeholder="Your Name" onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
            </div>
            <div class="form-group">
              <input type="email" class="form-control" required="" name="email" placeholder="Email Address" onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
            </div>
            <div class="form-group">
              <input type="number" class="form-control" required="" name="phone" minlength="10" maxlength="12" placeholder="Phone" onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
            </div>
            <div class="form-group form-btn">
              {loading == true ? (
                <div class="loader">
                  <img alt="loader" src={loader} />
                </div>
              ) : (
                <button type="submit" class="entery-submit" id="signupBtn" name="signupForm">
                  Reserve your discount
                </button>
              )}
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal >
  );
};
