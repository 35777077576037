import React, { useState, useEffect } from "react";
import { bannerart2min, bannerart3min, bannerart4min, bannerart5min, bannerart6min } from "../../assets/images";
import BannerForm from "../../Components/BannerForm";
import { WEBSITE_NAME } from "../../config";
import './index.css'
import { ModalSection } from "../../Components/modalSection";

export default function MainBanner() {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };

    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollTop(window.scrollY); // Update scroll position
        };

        window.addEventListener("scroll", handleScroll); // Listen to scroll event

        return () => {
            window.removeEventListener("scroll", handleScroll); // Cleanup listener on component unmount
        };
    }, []);

    // Adjust the top position of images based on scrollTop
    const imageStyle = (multiplier) => ({
        transform: `translateY(${scrollTop * multiplier}px)`,
        transition: 'transform 0.2s ease-out', // Smooth transition as user scrolls
    });
    return (
        <>
            <div class="banner">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-12 col-sm-12">
                            <div class="center-title">
                                <h2 class="large">Your Story, Our Words!</h2>
                                <h5>At {WEBSITE_NAME}, we specialize in bringing your story to life. Our skilled ghostwriters will work closely with you to turn your ideas into best-seller</h5>
                                <div class="banner-btn">
                                    <a href="javascript:;" class="call-btn" onClick={onOpenModal}>Get A Free Quote</a>
                                    <a href="javascript:;" class="chat-btn chat">Talk to Us</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-12 col-sm-12 align-item-end">
                            <div class="bannerForm">
                                <div class="formtop">
                                    <h2><span>UPTO 50% OFF</span></h2>
                                    <h3>ON YOUR NEXT GHOSTWRITING ORDER!</h3>
                                </div>
                                <p>Offer valid for the first <span>8 signups</span> only!</p>
                                <div class="mainform">
                                    <BannerForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner-row2">
                    <div className="banner-col">
                        <img
                            className="banner-art banner-art3 img-fluid "
                            src={bannerart3min}
                            alt="banner-art3-min"
                            style={imageStyle(-0.1)} // Moves up slower (multiplier -0.3)
                        />
                    </div>
                    <div className="banner-col">
                        <img
                            className="banner-art banner-art4 img-fluid "
                            src={bannerart4min}
                            alt="banner-art4-min"
                            style={imageStyle(-0.1)} // Moves up a bit faster (multiplier -0.2)
                        />
                    </div>
                    <div className="banner-col">
                        <img
                            className="banner-art banner-art5 img-fluid "
                            src={bannerart5min}
                            alt="banner-art5-min"
                            style={imageStyle(-0.1)} // Moves even faster (multiplier -0.1)
                        />
                        <img
                            className="banner-art banner-art2 img-fluid "
                            src={bannerart2min}
                            alt="banner-art2-min"
                            style={imageStyle(-0.1)} // Moves up more quickly (multiplier -0.5)
                        />
                    </div>
                </div>
                <div class="banner-row3">
                    <div className="banner-col"></div>
                    <div className="banner-col">
                        <div class="center-title">
                            <h2 class="text-center">Discover Your Perfect Ghostwriter! <br/> Writing Your Story Together!</h2>
                            <p class="text-center">We believe that every story is personal. Our ghostwriters are dedicated to capturing your voice and message, creating a compelling narrative that speaks to your audience. Our ghostwriters will transform ideas into a polished well-written book that stands out.</p>
                        </div>
                    </div>
                    <div className="banner-col">
                        <img
                            className="banner__art banner__art6 img-fluid"
                            src={bannerart6min}
                            alt="banner-art6-min"
                            style={imageStyle(-0.2)}
                        />
                    </div>
                </div>
            </div >
            <ModalSection open={open} onCloseModal={onCloseModal} />
        </>
    )
}