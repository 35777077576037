import React, {useEffect, useState} from 'react';
import './App.css';
import axios from 'axios';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import CtaSection from './Layout/CtaSection';
import Portfolio from './Layout/Portfolio';
import Writers from './Layout/Writers';
import Testimonial from './Layout/Testimonial';
import TheProcessSection from './Layout/TheProcessSection';
import MainBanner from './Layout/MainBanner';
import Brandlogos from './Layout/BrandLogos';

function App() {
  useEffect(() => {
    // Call the Laravel API using axios
    axios.get('https://serverlanes.com/api/chat-script')  // Update with the correct route
      .then(response => {
        const data = response.data;

        // Check if the chat script should be loaded
        if (data.enableChat) {
          // Dynamically create a script element and set its content
          const script = document.createElement('script');
          script.src = "https://static.zdassets.com/ekr/snippet.js?key=ab98f90e-1afd-4579-8832-7d2bce884fa9";
          script.id = "ze-snippet";
          document.head.appendChild(script); // Append to head
        }
      })
      .catch(error => {
        console.error('Error loading chat script:', error);
      });
  }, []);
  return (
    <div className="App">
      <Header/>
      <MainBanner/>
      <Brandlogos/>
      <TheProcessSection/>
      <Writers/>
      <Testimonial/>  
      <Portfolio/>
      <CtaSection/>
      <Footer/>
    </div>
  );
}

export default App;
