import { writers1 } from '../../assets/images'
import './index.css'

export default function Writers() {
    return (
        <>
            <section className="our-writers">
                <div className="container">
                    <div className="center-title">
                        <h5>Our Team</h5>
                        <h2>Meet Our Talented Team of Ghostwriters!</h2>
                        <p>With a diverse team of skilled writers, we bring a wealth of experience to your project. Our ghostwriters are accomplished authors and seasoned journalists, each bringing unique insights from prestigious publishing houses, respected news agencies, and top-tier creative writing programs.</p>
                    </div>
                </div>
                <div className='our-writers-image'>
                    <img src={writers1} />
                </div>
            </section>
        </>
    )
}