const Admin_Panel = 'info@turnerpublishingcompany.co';
const PHONE = '510 916 4827';
const PHONE_HREF = 'tel:5109164827';
const PHONE_TWO = '510 916 4827';
const PHONE_TWO_HREF = 'tel:5109164827';
const EMAIL = 'info@turnerpublishingcompany.co';
const EMAIL_HREF = 'mailto:info@turnerpublishingcompany.co';
const CONTACT_EMAIL = 'info@turnerpublishingcompany.co';
const CONTACT_EMAIL_HREF = 'mailto:info@turnerpublishingcompany.co';
const ADDRESS = '95 Third Street 2nd Floor San Francisco California 94103 United States of America';
const WEBSITE_NAME = 'Turner Publishing Company';
const SITE_CURRENCY_SYMBOLS = '$';
const WEBSITE_LOGO =
  'https://www.creativeghostwriters.org/logo.png';

export {
  Admin_Panel,
  PHONE,
  PHONE_HREF,
  PHONE_TWO,
  PHONE_TWO_HREF,
  EMAIL,
  EMAIL_HREF,
  CONTACT_EMAIL,
  CONTACT_EMAIL_HREF,
  ADDRESS,
  WEBSITE_NAME,
  SITE_CURRENCY_SYMBOLS,
  WEBSITE_LOGO,
};
