import React, { useState,useEffect } from "react";
import { logo, mainicon } from "../../assets/images";
import { PHONE, PHONE_HREF } from "../../config";
import './index.css'
import { ModalSection } from "../../Components/modalSection";

export default function Header() {

    useEffect(() => {
        // Function to open chat window
        const openChatWindow = () => {
            if (window.$zopim) {
                window.$zopim.livechat.window.show();
            }
        };

        // Function to toggle chat window
        const toggleChat = () => {
            if (window.$zopim) {
                window.$zopim.livechat.window.toggle();
            }
        };

        // Initialize chat and open by default
        const initializeChat = () => {
            const intervalId = setInterval(() => {
                if (window.$zopim && window.$zopim.livechat) {
                    clearInterval(intervalId);
                    openChatWindow();
                    listenForNewMessages();
                }
            }, 100); // Check every 100ms if chat widget is available
        };

        // Listen for new messages
        const listenForNewMessages = () => {
            if (window.$zopim && window.$zopim.livechat) {
                window.$zopim.livechat.setOnUnreadMsgs((numberOfUnreadMessages) => {
                    if (numberOfUnreadMessages > 0) {
                        openChatWindow();
                    }
                });
            }
        };

        initializeChat();
        const chatButtons = document.querySelectorAll('.chat'); // Select all buttons with the class 'chat'

        chatButtons.forEach(button => {
            button.addEventListener('click', toggleChat); // Add click event to each button
        });

        // Cleanup: Remove event listeners when the component unmounts
        return () => {
            chatButtons.forEach(button => {
                button.removeEventListener('click', toggleChat); // Remove click event from each button
            });
        };
    }, []);


    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    return (
        <>
            <header class="header_area">
                <div class="container">
                    <div class="navigation_area">
                        <div class="navbar p-0">
                            <a class="navbar-brand" href="javascript:;"><img src={logo} class="img-fluid mlogo" /></a>
                            <div class="connect_area d-flex align-items-center justify-content-center">
                                <div class="phone-btn same-icon">
                                    <a href={PHONE_HREF}>
                                        <div class="p-s">
                                            <span>TALK TO US</span>
                                            <p> {PHONE}</p>
                                        </div>
                                    </a>
                                </div>
                                <div class="chat-btn same-icon ">
                                    <a href="javascript:;" class="chat"> <img src={mainicon} alt="" />
                                        <div class="p-s">
                                            <span>24/7 AVAILABLE</span>
                                            <p>Live Chat</p>
                                        </div>
                                    </a>
                                </div>
                                <a href="javascript:;" class="btn header-btn" onClick={onOpenModal}>Get A Free Quote</a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <ModalSection open={open} onCloseModal={onCloseModal} />
        </>
    )
}