import Testimonialslider from '../../Components/Testimonialslider';
import { WEBSITE_NAME } from '../../config';
import './index.css'
import "slick-carousel/slick/slick.css"; 


export default function Testimonial() {
    return (
        <>
            <div class="testimonials">
                <div class="container">
                    <div class="title">
                        <h5>Testimonials</h5>
                        <h2>Hear It from Our Clients!</h2>
                        <p>At {WEBSITE_NAME}, we take pride in helping countless individuals share their unique narratives. Don’t just take our word for it discover how we’ve made a difference in the lives of our clients.</p>
                    </div>
                    <Testimonialslider/>
                </div>
            </div>
        </>
    )
}