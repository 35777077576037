import { portimg1, portimg2, portimg3, portimg4, portimg5, portimg6, portimg7, portimg8 } from '../../assets/images'
import './index.css'

export default function Portfolio() {
    return (
        <>
            <section className="portfolio-section">
                <div className="container">
                    <div className="title text-center">
                        <h2>Showcase of Our Work</h2>
                        <p>Our portfolio features a collection of diverse projects that highlight our commitment to quality and storytelling</p>
                    </div>
                </div>
                <div className="container">
                    <div className='row justify-content-center'>
                        <div className='col-lg-12 row'>
                            <div className='col-sm-3'>
                                <div className='portfolio-img'>
                                    <img src={portimg1} />
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='portfolio-img'>
                                    <img src={portimg2} />
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='portfolio-img'>
                                    <img src={portimg3} />
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='portfolio-img'>
                                    <img src={portimg4} />
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='portfolio-img'>
                                    <img src={portimg5} />
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='portfolio-img'>
                                    <img src={portimg6} />
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='portfolio-img'>
                                    <img src={portimg7} />
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='portfolio-img'>
                                    <img src={portimg8} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}