import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WorkSectionSlider = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <Slider {...settings}>
            <div className="slide-item">
                <div className="works-list">
                    <div className="works-number">
                        <span>1</span>
                    </div>
                    <div className="works-info">
                        <h4>Sign Up</h4>
                        <p>Get started by reaching out to us via phone or live chat. We’re excited to help you on your journey!</p>
                    </div>
                </div>
                <div className="works-list">
                    <div className="works-number">
                        <span>2</span>
                    </div>
                    <div className="works-info">
                        <h4>Research and Planning</h4>
                        <p>Our team conducts thorough research and collaborates with you to outline your book, ensuring we capture your vision perfectly</p>
                    </div>
                </div>
                <div className="works-list">
                    <div className="works-number">
                        <span>3</span>
                    </div>
                    <div className="works-info">
                        <h4>Drafting the First Chapter</h4>
                        <p>We write the first chapter based on your outline and send it to you for your feedback. Your input is invaluable in shaping the story!</p>
                    </div>
                </div>
            </div>

            <div className="slide-item">
                <div className="works-list">
                    <div className="works-number">
                        <span>4</span>
                    </div>
                    <div className="works-info">
                        <h4>Editing & Refining</h4>
                        <p>Once the draft is complete, our editors meticulously review the manuscript, making necessary edits and corrections to enhance clarity and flow.</p>
                    </div>
                </div>
                <div className="works-list">
                    <div className="works-number">
                        <span>5</span>
                    </div>
                    <div className="works-info">
                        <h4>Typesetting & Design</h4>
                        <p>We format your book, adding the right fonts and graphics. Our design team will also create eye-catching front and back covers to attract readers.</p>
                    </div>
                </div>
                <div className="works-list">
                    <div className="works-number">
                        <span>6</span>
                    </div>
                    <div className="works-info">
                        <h4>Publishing & Promotion</h4>
                        <p>Finally, we publish your book in your preferred format and implement a tailored marketing strategy to help you reach your audience effectively.</p>
                    </div>
                </div>
            </div>
        </Slider>
    );
};

export default WorkSectionSlider;
