import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BBC1, forbestransparent, NYTimes1, TechCrunch1 } from "../../assets/images";

const BrandsSliderMobile = () => {
    const [isSliderActive, setIsSliderActive] = useState(window.innerWidth < 992);

    // Update the state when resizing the window
    useEffect(() => {
        const handleResize = () => {
            setIsSliderActive(window.innerWidth < 992);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="brand-slider">
            {isSliderActive ? (
                <Slider {...sliderSettings}>
                    <div className="items">
                        <div className="brands-logo">
                            <img src={TechCrunch1} alt="TechCrunch-1" className="img-fluid" />
                        </div>
                    </div>

                    <div className="items">
                        <div className="brands-logo">
                            <img src={forbestransparent} alt="forbes-transparent" className="img-fluid" />
                        </div>
                    </div>

                    <div className="items">
                        <div className="brands-logo">
                            <img src={BBC1} alt="BBC-1" className="img-fluid" />
                        </div>
                    </div>

                    <div className="items">
                        <div className="brands-logo">
                            <img src={NYTimes1} alt="NYTimes-1" className="img-fluid" />
                        </div>
                    </div>
                </Slider>
            ) : (
                <div className="slider">
                    <div className="items">
                        <div className="brands-logo">
                            <img src={TechCrunch1} alt="TechCrunch-1" className="img-fluid" />
                        </div>
                    </div>

                    <div className="items">
                        <div className="brands-logo">
                            <img src={forbestransparent} alt="forbes-transparent" className="img-fluid" />
                        </div>
                    </div>

                    <div className="items">
                        <div className="brands-logo">
                            <img src={BBC1} alt="BBC-1" className="img-fluid" />
                        </div>
                    </div>

                    <div className="items">
                        <div className="brands-logo">
                            <img src={NYTimes1} alt="NYTimes-1" className="img-fluid" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BrandsSliderMobile;
