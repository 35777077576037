import { additionalbg } from '../../assets/images'
import WorkSectionSlider from '../../Components/WorkSectionSlider'
import './index.css'

export default function TheProcessSection() {
    return (
        <>
            <div class="works">
                {/* <div class="container container-lineicon">
                    <img src={additionalbg} alt="additional-bg-1" style={{ maxWidth: "20%", height: "auto" }} />
                </div> */}
                <div class="container">
                    <div class="works-top">
                        <h5>how it works</h5>
                    </div>
                    <div class="content d-flex justify-content-between ">
                        <div class="works-left ">
                            <h2>Our Simple Process to Bring Your Story to Life</h2>
                        </div>
                        <div class="works-right">
                            <p>Let our dedicated ghostwriters take your vision and turn it into a captivating story. No need to stress focus on anything while we handle the words that will showcase your journey.
                            </p>
                        </div>
                    </div>

                    <div class="works-content d-flex justify-content-between">
                        <div class="works-left">
                            <div class="works-video d-flex justify-content-around align-items-center">
                            </div>
                        </div>
                        <div class="works-right">
                            <WorkSectionSlider />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}