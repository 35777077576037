import React, { useEffect, useState } from 'react';
import './index.css';
import usePageMeta from '../../usePageTitle';
// import { Helmet } from 'react-helmet';

export const ThankYou = () => {
  usePageMeta();
  const [title, setTitle] = useState('Thank you');

  useEffect(() => {
    const fetchData = async () => {
      const response = await new Promise(resolve => {
        // setTimeout(() => resolve('Fetched Title'), 2000);
        resolve('Thank You For Contacting Us')
      });
      setTitle(response);
    };

    fetchData();
  }, []);


  return (
    <div>
      {/* <Helmet>
        <title>{title}</title>
      </Helmet> */}
      <section className="thankyou_sec">
        <div className="container">
          <div className="main-thankyou">
            <div className="site-header">
              <h1
                className="site-header__title"
                data-lead-id="site-header-title"
              >
                THANK YOU!
              </h1>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-8">
                <div className="main-content">
                  <i
                    className="fa fa-check main-content__checkmark"
                    id="checkmark"
                  ></i>
                  <p
                    className="main-content__body"
                    data-lead-id="main-content-body"
                  >
                    Thank you for filling in your information. You have been
                    successfully connected with us. We look forward to doing
                    business with you. One of our account managers will get back
                    to you shortly.
                  </p>
                  <p className="main-content__body">
                    Feel free to get in touch with us at any time.
                  </p>
                  <p>
                    <a
                      href="javascript:;"
                      className="start_live_chat chat liveChatButton"
                    >
                      <i className="chat-icon for-sprite"></i>
                      Start Live Chat
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
