import React,{useState} from 'react';
import { ModalSection } from '../../Components/modalSection';
import './index.css'

export default function CtaSection() {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    return (
        <>
            <section className="cta-sec">
                <div className="container">
                    <div className="row">
                        <div className='col-md-12'>
                            <div className="inner">
                                {/* <h5 className='text-center text-white'> GET STARTED</h5> */}
                                <h1 className='text-center text-white'>Ready to Get Started?</h1>
                                <p className='text-center text-white'>Let’s talk about how we can help you bring your story to life. Whether you have questions or want to get a quote, we're here for you!</p>
                            </div>
                            <div className='cta-btn'>
                                <a href='Javascript:;' className='cta-call' onClick={onOpenModal}>Get A Free Quote</a>
                                <a href='Javascript:;' className='chat cta-chat'>Talk To Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ModalSection open={open} onCloseModal={onCloseModal} />
        </>
    )
}